import { useEffect, useState } from 'react'

import { useDisclosure } from '@mantine/hooks'

import Button from 'src/components/Buttons/Button/Button'
import IconAdd from 'src/icons/IconAdd'

import EditEmploymentModal from './components/EditEmploymentModal'
import EditUnemploymentModal from './components/EditUnemploymentModal'
import EmploymentTable from './components/EmploymentTable'
import { ActionIcon, Box, Menu } from '@mantine/core'
import IconChevronDown from 'src/icons/IconChevronDown'

const EmploymentCard = ({ application, handleUpdateApplication }) => {
  const [editModalOpened, { open: openEditModal, close: closeEditModal }] =
    useDisclosure(false)
  const [tempExperiences, setTempExperiences] = useState(
    application.employmentExperiences
  )
  const [currentIndex, setCurrentIndex] = useState<number>(
    tempExperiences.length > 0 ? 0 : null
  )

  const handleCancel = () => {
    setTempExperiences([...application.employmentExperiences])
  }

  const handleSave = (experiences = null) => {
    let updates = {}
    const temp = experiences || tempExperiences
    updates = {
      employmentExperiences: temp.map((item) => {
        return {
          ...(item.employmentExperienceId && {
            employmentExperienceId: item.employmentExperienceId,
          }),
          companyName: item.companyName,
          startDate: item.startDate,
          address: {
            street: item.address?.street || '',
            city: item.address?.city || '',
            state: item.address?.state || '',
            zipCode: item.address?.zipCode || '',
            country: item.address?.country || '',
          },
          dotNumber: item.dotNumber,
          email: item.email,
          contactName: item.contactName,
          isUnemployment: item.isUnemployment,
          unemploymentReason: item.unemploymentReason,
          ...(item.endDate !== undefined &&
            item.endDate !== '' && { endDate: item.endDate }),
          ...(item.position !== undefined &&
            item.position !== '' && { position: item.position }),
          ...(item.reasonForLeaving !== undefined && {
            reasonForLeaving: item.reasonForLeaving,
          }),
          ...(item.isDriverTerminated !== undefined && {
            isDriverTerminated: item.isDriverTerminated,
          }),
          ...(item.isDriverSubjectToFMCSRs !== undefined && {
            isDriverSubjectToFMCSRs: item.isDriverSubjectToFMCSRs,
          }),
          ...(item.isJobDesignatedAsSafetySensitiveFunctionUnderDOT !==
            undefined && {
            isJobDesignatedAsSafetySensitiveFunctionUnderDOT:
              item.isJobDesignatedAsSafetySensitiveFunctionUnderDOT,
          }),
          ...(item.shouldContactEmployer !== undefined && {
            shouldContactEmployer: item.shouldContactEmployer,
          }),
          ...(item.phone !== undefined && { phone: item.phone }),
          ...(item.faxNumber !== undefined && { faxNumber: item.faxNumber }),
          ...(item.dotNumber !== undefined && { dotNumber: item.dotNumber }),
        }
      }),
    }
    handleUpdateApplication(updates).then((res) => {
      if (!res.errors) {
        const experiences = res.data.updateApplication.employmentExperiences
        setTempExperiences([...experiences])
        setCurrentIndex(null)
        closeEditModal()
      }
    })
  }

  const handleDelete = (id) => {
    const temp = [...tempExperiences]
    const index = temp.findIndex((item) => item.employmentExperienceId === id)
    temp.splice(index, 1)
    setTempExperiences(temp)
    handleSave(temp)
  }

  const addUnemployment = (isUnemployment) => {
    const temp = [...tempExperiences]
    temp.push({
      companyName: '',
      address: {
        street: '',
        city: '',
        state: '',
        zipCode: '',
        country: '',
      },
      dotNumber: '',
      phone: '',
      faxNumber: '',
      reasonForLeaving: '',
      position: 'Truck Driver',
      contactName: '',
      email: '',
      startDate: new Date(),
      endDate: new Date(),
      isDriverTerminated: false,
      isDriverSubjectToFMCSRs: true,
      isJobDesignatedAsSafetySensitiveFunctionUnderDOT: true,
      shouldContactEmployer: true,
      isUnemployment: isUnemployment,
    })
    setTempExperiences(temp)
    setCurrentIndex(temp.length - 1)
    openEditModal()
  }

  const isCurrentItemUnemployment =
    tempExperiences[currentIndex]?.isUnemployment

  return (
    <div
      className={`flex w-full flex-col gap-4 rounded-lg border border-doubleNickel-gray-200 p-4`}
    >
      <div className=" text-md flex flex-row items-center justify-between font-semibold text-doubleNickel-black">
        Employment History
        <Menu shadow="md" position="bottom-end">
          <Menu.Target>
            <Box className="font-lg flex flex-row items-center justify-between font-bold text-doubleNickel-gray-700">
              <ActionIcon className="h-8 w-8 border border-doubleNickel-gray-200 bg-doubleNickel-white shadow-sm hover:bg-doubleNickel-gray-200">
                <IconAdd className="h-4 w-4 fill-none stroke-doubleNickel-gray-500" />
              </ActionIcon>
            </Box>
          </Menu.Target>
          <Menu.Dropdown className="w-40">
            <Menu.Item
              className={` text-doubleNickel-gray-700`}
              onClick={() => addUnemployment(false)}
            >
              Add previous employment
            </Menu.Item>
            <Menu.Item
              className="text-doubleNickel-gray-700"
              onClick={() => addUnemployment(true)}
            >
              Add unemployment period
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </div>
      <EmploymentTable
        experiences={application.employmentExperiences}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        handleDelete={handleDelete}
        handleEdit={openEditModal}
      />
      {editModalOpened && !isCurrentItemUnemployment && (
        <EditEmploymentModal
          opened={editModalOpened}
          close={closeEditModal}
          experiences={tempExperiences}
          setExperiences={setTempExperiences}
          currentIndex={currentIndex}
          handleSave={handleSave}
          handleCancel={handleCancel}
        />
      )}
      {editModalOpened && isCurrentItemUnemployment && (
        <EditUnemploymentModal
          opened={editModalOpened}
          close={closeEditModal}
          experiences={tempExperiences}
          setExperiences={setTempExperiences}
          currentIndex={currentIndex}
          handleSave={handleSave}
          handleCancel={handleCancel}
        />
      )}
    </div>
  )
}

export default EmploymentCard
